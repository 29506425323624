<template>
  <div class="loginBox">
    <a-row class="login">
      <a-col class="left" :xl="14" :lg="14">
        <img class="leftPic" src="@/assets/images/login/loginPic.png" alt="" />
      </a-col>
      <a-col class="right" :xl="10" :lg="10">
        <div class="rightFix">
          <a-select
            ref="select"
            v-model:value="selectValue"
            style="width: 164px"
            :placeholder="t('languMsg')"
            @change="handleChange"
            size="large"
          >
            <!--  @focus="focus"
            @change="handleChange" -->
            <a-select-option value="zh_CN">Chinese 中文</a-select-option>
            <a-select-option value="en_US">English 英语</a-select-option>
          </a-select>
        </div>
        <div class="contBox">
          <div class="navBox">
            <img
              class="logoImg"
              src="@/assets/images/login/logoIcon.png"
              alt=""
            />
            <!-- <span>{{ t('logoName') }}</span> -->
          </div>
          <div class="formBox">
            <a-form ref="ruleForm" :model="form" :rules="rules">
              <a-space direction="vertical" :size="24" style="width: 100%">
                <a-form-item name="name">
                  <a-input
                    class="lg-form-input"
                    v-model:value="form.name"
                    :placeholder="t('login.userNamePla')"
                    @pressEnter="submin"
                    style="
                      padding-left: 38px;
                      border-radius: 10px;
                      position: relative;
                      height: 40px;
                    "
                  >
                    <!-- <template #prefix>
                      <UserOutlined
                        style="
                          color: rgba(0, 0, 0, 0.45);
                          font-size: 18px;
                          margin-right: 6px;
                        "
                        type="user"
                      />
                    </template> -->
                  </a-input>
                  <div class="formItemName">
                    {{ t('login.usernameLabel') }}
                  </div>
                  <UserOutlined
                    class="inputIcon"
                    style="
                      color: rgba(0, 0, 0, 0.45);
                      font-size: 18px;
                      margin-right: 6px;
                    "
                    type="user"
                  />
                </a-form-item>
                <a-form-item name="password" style="margin-top: 20px">
                  <a-input
                    class="lg-form-input"
                    :type="inputType ? 'password' : 'text'"
                    style="
                      padding-left: 38px;
                      border-radius: 10px;
                      position: relative;
                      height: 40px;
                    "
                    v-model:value="form.password"
                    :placeholder="t('login.passwordPla')"
                  >
                  </a-input>
                  <div class="formItemName">
                    {{ t('login.passwordLabel') }}
                  </div>
                  <KeyOutlined class="inputIcon" style="" />
                  <div class="eyeBox">
                    <EyeInvisibleOutlined v-if="inputType" @click="typeClick" />
                    <EyeOutlined v-else @click="typeClick" />
                  </div>
                </a-form-item>
              </a-space>
            </a-form>
            <a-button
              class="subBtn"
              :loading="loading"
              type="primary"
              block
              @click="submin"
            >
              {{ t('login.login') }}
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {
  UserOutlined,
  KeyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { ref, defineComponent, onMounted, reactive, watch } from 'vue'
// import { login } from '@/api/login.js';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoginIndex',
  components: { UserOutlined, KeyOutlined, EyeInvisibleOutlined, EyeOutlined },
  setup() {
    const { t, locale } = useI18n()
    const rules = ref()
    const inputType = ref(true)
    const selectValue = ref(null)
    const router = useRouter()
    const store = useStore()
    let loading = ref(false)
    let rememberMeFlag = ref(false)
    const ruleForm = ref()
    const form = reactive({
      name: '',
      password: '',
    })
    //判断语言并赋值
    const payload = localStorage.getItem('languageSet') || 'zh_CN'
    if (payload) {
      selectValue.value = payload
    }
    //根据语言变化重新赋值rules
    const rulesFun = () => {
      rules.value = {
        name: {
          required: true,
          message: t('login.userNamePlas'),
          trigger: 'blur',
        },
        password: {
          required: true,
          message: t('login.passwordPla'),
          trigger: 'blur',
        },
      }
      console.log(rules.value, 'rules.value--- rules.value')
    }
    rulesFun()

    //切换语言
    const handleChange = (el) => {
      console.log(el, 'el-el')
      locale.value = el
      localStorage.setItem('languageSet', el)
      rulesFun()
    }
    //登录
    const submin = () => {
      ruleForm.value
        .validate()
        .then(() => {
          loading = true
          const loginData = {
            account: form.name,
            password: form.password,
          }
          store
            .dispatch('login', loginData)
            .then((userInfo) => {
              loading = false
              if (store.getters.userType) {
                setRouterRedirect()
              } else {
                if (userInfo.platformNames) {
                  if (userInfo.platformNames.length == 1) {
                    setRouterRedirect(userInfo.platformNames[0])
                  } else {
                    router.push('/transfer')
                  }
                } else {
                  setRouterRedirect()
                }
              }
            })
            .catch(() => {
              loading = false
            })
          // login(loginData).then((res) => {
          //   loading = false;
          //   if (res.code === 200) {
          //     // window.localStorage.setItem('userName', form.value.name);
          //     // window.localStorage.setItem('un', form.value.password);
          //     // window.localStorage.setItem('pw', form.value.password);
          //     store.commit('setUserName', form.name);
          //     store.commit('setToken', res.data);
          //     router.push({
          //       name: 'index',
          //     });
          //   }
          // });
        })
        .catch(() => {})
    }
    async function setRouterRedirect(userType) {
      await store.dispatch('setUserType', userType)
      store.dispatch('routerRedirect')
    }
    //监听语言切换，提示信息语言实时响应切换
    watch(locale, () => {
      rulesFun()
    })

    function rememberMe() {
      rememberMeFlag.value = !rememberMeFlag.value
      window.localStorage.setItem('rememberMe', rememberMeFlag.value)
    }

    onMounted(() => {
      if (window.localStorage.getItem('rememberMe') == 'true') {
        // form.value.name = window.localStorage.getItem('un');
        // form.value.password = window.localStorage.getItem('pw');
        rememberMeFlag.value =
          window.localStorage.getItem('rememberMe') == 'true' ? true : false
      }
    })
    const typeClick = () => {
      inputType.value = !inputType.value
    }
    return {
      t,
      inputType,
      typeClick,
      selectValue,
      //   name,
      //   password,
      ruleForm,
      form,
      rules,
      submin,
      loading,
      rememberMeFlag,
      rememberMe,
      handleChange,
    }
  },
})
</script>
<style scoped lang="less">
.inputIcon {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 18px;
  margin-right: 6px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.eyeBox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-size: 14px;
}
* {
  margin: 0;
  padding: 0;
}
.loginBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .left {
      width: 58%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #faf9fe;
      .leftPic {
        width: 500px;
        height: 500px;
        display: flex;
      }
    }
  }
  .right {
    width: 42%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: relative;
  }
}
.contBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  .navBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 67px;
    .logoImg {
      width: 131px;
      height: 48px;
      margin-right: 12px;
    }
    span {
      font-size: 20px;
      line-height: 48px;
      color: #131011;
    }
  }
  .formBox {
    width: 330px;
    .lg-form-input {
      width: 100%;
      height: 40px;
      padding: 0px 10px;
      border-radius: 8px;
      border: 1px solid #d2d2d2;
      overflow: hidden;
      box-sizing: border-box;
      font-size: 14px;
      margin: 0;
    }
  }
}
.formItemName {
  position: absolute;
  top: -22px;
  left: 2px;
  font-size: 12px;
  line-height: 18px;
  color: #131011;
  margin-bottom: 4px;
}
.subBtn {
  width: 330px;
  height: 40px;
  background: #131011;
  border-radius: 8px;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
  border: none;
}
.rightFix {
  position: absolute;
  right: 32px;
  top: 32px;
}

#login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: #4e73df;
  //background-color: #F7F7FF;
  //background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
  padding: 0 10px;

  #bj-content {
    height: 500px;
    //background: white;
    border-radius: 7px;
    overflow: hidden;

    #lg-right,
    #lg-left {
      width: 450px;
      height: 100%;
      position: relative;
    }

    #lg-right {
      padding: 48px;
      font-weight: 400;
      color: #858796;

      h1 {
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        color: #3a3b45 !important;
        margin: 0;
        padding: 0;
        position: relative;
        span {
          font-size: 18px;
          margin-left: 5px;
        }
      }

      #lg-form {
        width: 100%;
        margin-top: 20px;
        //border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding-bottom: 20px;
        position: relative;

        .lg-form-input {
          width: 100%;
          height: 40px;
          padding: 0px 10px;
          border-radius: 8px;
          border: 1px solid #d2d2d2;
          overflow: hidden;
          box-sizing: border-box;
          font-size: 14px;
          margin: 0;
        }

        .lg-control-label {
          height: 50px;
          display: inline-block;
          line-height: 50px;
          position: relative;
          padding-left: 26px;
          font-size: 14px;
          color: #858796;
        }

        .lg-control-label::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          width: 16px;
          height: 16px;
          pointer-events: none;
          content: '';
          background-color: #fff;
          border: #b7b9cc solid 1px;
          border-radius: 4px;
          transition: all 0.3s;
        }

        .lg-control-label:hover::before {
          box-shadow: 0px 0px 5px #4e73df;
          transition: box-shadow 0.3s;
        }

        .lg-control-label-s::before {
          border-color: #4e73df;
          background-color: #4e73df;
        }

        .lg-control-label-s::after {
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          display: block;
          width: 16px;
          height: 16px;
          content: '';
          border-color: #4e73df;
          background-color: #4e73df;
          background: 50%/50% 50% no-repeat;
          background-image: url('~@/assets/images/login/lg-1.svg');
        }

        .lg-form-button {
          color: #fff;
          background-color: #1890ff;
          border-color: #1890ff;
          width: 100%;
          height: 45px;
          border-radius: 24px;
          margin-top: 30px;
        }
      }
    }

    #lg-left {
      background: url('~@/assets/images/login/login-bj.svg');
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  #login-content {
    width: 500px;
    min-height: 0px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    padding: 30px 30px 10px;
    position: relative;

    .login-input-title {
      font-size: 24px;
      color: white;
      float: left;
      position: absolute;
      top: -80px;
      left: 0;
    }
  }

  .login-button {
    border-radius: 5px;
    height: 40px;
  }
}
</style>
<style>
.ant-form-item-explain-error {
  padding-left: 15px !important;
  margin-top: 5px !important;
}
</style>
